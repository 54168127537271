const tableData = [
  {
    id: 1,
    image: 'src',
    name: 'Recruiting Manemailr',
    email: 'email@teste.com',
    isRoot: true
  }, {
    id: 2,
    image: 'src',
    name: 'Quality Engineer',
    email: 'email@teste.com',
    isRoot: false
  }, {
    id: 3,
    image: 'src',
    name: 'Senior Editor',
    email: 'email@teste.com',
    isRoot: false
  }, {
    id: 4,
    image: 'src',
    name: 'Programmer II',
    email: 'email@teste.com',
    isRoot: true
  },
  {
    id: 5,
    image: 'src',
    name: 'Data Coordiator',
    email: 'email@teste.com',
    isRoot: false
  }, {
    id: 6,
    image: 'src',
    name: 'Data Coordiator',
    email: 'email@teste.com',
    isRoot: true
  }, 
]

export { tableData };
