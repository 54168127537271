<script>
/**
 * Page-header component
 */
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    enableCad: {
      type: Boolean,
      default: true,
    },
    addText: {
      type: String,
      default: "Adicionar Novo"
    }
  },
  methods: {
    emitCad() {
      this.$emit('newCad', true);
    }
  }
};
</script>

<template>
  <!-- start page title -->
  <div class="row align-items-center">
    <div class="col-sm-6">
      <div class="page-title-box">
        <h4 class="font-size-18">{{ title }}</h4>
        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="col-sm-6" v-if="enableCad">
      <div class="float-end d-none d-md-block">
        <b-dropdown right variant="primary" menu-class="dropdown-menu-end">
          <template v-slot:button-content>
            <i class="mdi mdi-cog me-2"></i> Gerenciar
          </template>
          <b-dropdown-item @click="emitCad">{{addText}}</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
